.signInModalCard {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  max-width: 340px;
  padding: 24px;
}

.signInModalCardTitle {
  color: var(--black);
  line-height: 1.1;
  font-size: 1.44rem;
  font-family: var(--font-family);
  font-weight: 700;
  margin-bottom: 8px;
  letter-spacing: -0.25px;
}

.steps div {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 48px;
}

.steps p {
  color: var(--black);
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 4px;
}

.steps span {
  border-radius: 20px;
  width: 100%;
  height: 6px;
  margin: 0px;
}

.steps span.inactiveStep {
  background-color: #e9e9e9;
}

.steps span.activeStep {
  background-color: var(--dark-blue);
}

.signInModalCard a {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--blue);
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
}

.signInModalCard hr {
  margin: 32px 0;
  border: none;
  border-top: var(--border);
}

.signInModalCard a:hover {
  text-decoration: none;
}

.signInModalCardBody span {
  display: block;
  color: var(--medium);
  letter-spacing: 1px;
  font-size: 0.7rem;
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.signInModalCardBtns {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
}

.actionSignInBtn {
  border: 1px solid var(--dark-blue);
  background-color: var(--dark-blue);
  border-radius: 8px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 10px 16px;
}

.actionSignInBtn:disabled {
  background-color: #e9e9e980;
  border: 1px solid #e9e9e9;
  color: var(--medium);
  cursor: not-allowed;
}

.cancelSignInBtn {
  border: 1px solid var(--dark-blue);
  background-color: var(--white);
  border-radius: 8px;
  color: var(--dark-blue);
  cursor: pointer;
  font-weight: 600;
  padding: 8px 16px;
}

.signInModalError {
  background-color: rgba(255, 0, 0, 0.05);
  border-radius: 0px 4px 4px 0px;
  border-left: 2px solid red;
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 16px;
  word-wrap: break-word;
  overflow: hidden;
  padding: 8px;
}