/* General Modal */
.modal {
  background-color: #23242727;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100svh;
  z-index: 1000;
}

.modalContainer {
  width: auto;
  max-width: 360px;
  outline: none;
  position: relative;
  user-select: none;
}

/* Search Modal */
.searchModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}